import React, { Component } from 'react';
import { ajax_wrapper } from 'functions';
import { Button, Form, TextInput, Select, Radios } from 'library';

import { DOLLAR_STYLING, STATEABBREVIATIONS } from 'constants';
import { UnlockButton } from 'components';

const TYPE_TO_FORM_NAME = {
    'Age Requirement': 'age',
    'Income Requirement': 'income',
    'Disability Requirement': 'disabled',
    'Veteran Requirement': 'veteran',
    'Blind Requirement': 'blind',
    'Widow Requirement': 'widow',
    'Home Ownership Requirement': 'home_ownership',
};

const EXEMPTION_LOOKUP = {
    Age: (
        <div className="exemption-line-item">
            <Radios
                name="age"
                className="input"
                boolean={true}
                radio_type={'outline-dark'}
            />
            <div className="label">
                <img src="/static/images/person.svg" />
                <div className="text">
                    <label>Exemption based on your age</label>
                    <div>You are 65 or older.</div>
                </div>
            </div>
        </div>
    ),
    Income: (
        <div className="exemption-line-item">
            <TextInput name="income" className="input" />
            <div className="label">
                <img src="/static/images/dollar.svg" />
                <div className="text">
                    <label>Exemption based on income</label>
                    <div>What is your approximate household income?</div>
                </div>
            </div>
        </div>
    ),
    Disabled: (
        <div className="exemption-line-item">
            <Radios
                name="disabled"
                className="input"
                boolean={true}
                radio_type={'outline-dark'}
            />
            <div className="label">
                <img src="/static/images/pentagon.svg" />
                <div className="text">
                    <label>Social Security Administration Disable status</label>
                    <div>
                        You or your spouse have been determined to be totally
                        and permanently disabled under the rules of the Social
                        Security Administration
                    </div>
                </div>
            </div>
        </div>
    ),
    Veteran: (
        <div className="exemption-line-item">
            <Radios
                name="veteran"
                className="input"
                boolean={true}
                radio_type={'outline-dark'}
            />
            <div className="label">
                <img src="/static/images/clock.svg" />
                <div className="text">
                    <label>Veteran of the United States Armed Forces</label>
                    <div>
                        You or your spouse are a veteran of the United States
                        Armed Forces and have a service connected disability.
                    </div>
                </div>
            </div>
        </div>
    ),
    Blind: (
        <div className="exemption-line-item">
            <Radios
                name="blind"
                className="input"
                boolean={true}
                radio_type={'outline-dark'}
            />
            <div className="label">
                <img src="/static/images/person.svg" />
                <div className="text">
                    <label>Social Security Administration Blind status</label>
                    <div>
                        Are you or your spouse determined to be blind under the
                        rules of the Social Security Administration?"
                    </div>
                </div>
            </div>
        </div>
    ),
    Widow: (
        <Select
            name="widow"
            label="Are you a widow or widower and have not re-married?"
            options={[
                { text: 'No', value: 'No' },
                { text: 'Yes', value: 'Yes' },
            ]}
        />
    ),
    'Home Ownership': (
        <TextInput
            name="home_ownership"
            label="How long you owned and lived in your home?"
        />
    ),
};

export default class Exemptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            qualify_for_exemptions: null,
        };

        this.demos = {};

        this.submit_demographics = this.submit_demographics.bind(this);
        this.clear_form = this.clear_form.bind(this);
    }

    submit_demographics = (name, data) => {
        console.log('Submit Demos', name, data);
        for (let key in data) {
            this.demos[key] = data[key];
        }
        let all_fields = true;
        for (let exemption of this.props.exemptions) {
            for (let req of exemption.requirements_list) {
                if (req.type == 'Home Ownership Requirement') {
                    continue;
                }
                if (!(TYPE_TO_FORM_NAME[req.type] in this.demos)) {
                    console.log(
                        'Missing',
                        req.type,
                        TYPE_TO_FORM_NAME[req.type],
                    );
                    all_fields = false;
                }
            }
        }

        if (
            window.secret_react_state.user &&
            window.secret_react_state.user.id &&
            all_fields
        ) {
            ajax_wrapper(
                'POST',
                '/user/submit_demographics/',
                { data: this.demos },
                console.log,
            );

            this.setState({
                submitted: true,
                qualify_for_exemptions: this.demos,
            });
        }

        console.log('All Fields', all_fields);
    };

    clear_form() {
        this.demos = {};
        this.setState({ submitted: false, qualify_for_exemptions: null });
    }

    render() {
        let property = this.props.property;
        let address = property.propertyInfo.address;
        let address_state = STATEABBREVIATIONS[address.state];

        let no_exemptions = (
            <p>We currently have no exemptions listed for {address_state}</p>
        );

        let exemption_questions = [];
        let exemption_types = {};
        let total_exemption_value = 0;

        let exemption_list = [];
        for (let exemption of this.props.exemptions) {
            total_exemption_value += exemption.max_value;
            let requirement_list = [];
            let qualify = true;
            for (let req of exemption.requirements_list) {
                if (req.type == 'Age Requirement') {
                    if (!exemption_types['Age']) {
                        exemption_types['Age'] = true;
                        exemption_questions.push(EXEMPTION_LOOKUP['Age']);
                    }

                    if (
                        this.state.qualify_for_exemptions &&
                        !this.state.qualify_for_exemptions['age'] //< req.value
                    ) {
                        console.log(
                            'Failed Age',
                            this.state.qualify_for_exemptions['age'],
                            req.value,
                        );
                        qualify = false;
                    }
                    requirement_list.push(
                        <li>
                            You or your spouse are over the age of {req.value}.
                        </li>,
                    );
                } else if (req.type == 'Income Requirement') {
                    if (!exemption_types['Income']) {
                        exemption_types['Income'] = true;
                        exemption_questions.push(EXEMPTION_LOOKUP['Income']);
                    }
                    if (
                        this.state.qualify_for_exemptions &&
                        this.state.qualify_for_exemptions['income'] > req.value
                    ) {
                        //console.log("Failed Income", this.state.qualify_for_exemptions['income'], req.value);
                        qualify = false;
                    }
                    requirement_list.push(
                        <li>
                            Your household income is below{' '}
                            {req.value.toLocaleString('en-US', DOLLAR_STYLING)}.
                        </li>,
                    );
                } else if (req.type == 'Disability Requirement') {
                    if (!exemption_types['Disabled']) {
                        exemption_types['Disabled'] = true;
                        exemption_questions.push(EXEMPTION_LOOKUP['Disabled']);
                    }
                    if (
                        this.state.qualify_for_exemptions &&
                        this.state.qualify_for_exemptions['disabled'] == false
                    ) {
                        qualify = false;
                    } else if (this.state.qualify_for_exemptions) {
                        console.log(
                            'Disabled Requirement',
                            exemption.name,
                            this.state.qualify_for_exemptions['disabled'],
                            req.value,
                        );
                    }
                    requirement_list.push(
                        <li>
                            You or your spouse have been determined to be
                            disabled under the rules of the Social Security
                            Administration
                        </li>,
                    );
                } else if (req.type == 'Veteran Requirement') {
                    if (!exemption_types['Veteran']) {
                        exemption_types['Veteran'] = true;
                        exemption_questions.push(EXEMPTION_LOOKUP['Veteran']);
                    }
                    if (
                        this.state.qualify_for_exemptions &&
                        this.state.qualify_for_exemptions['veteran'] == false
                    ) {
                        qualify = false;
                    }
                    requirement_list.push(
                        <li>
                            You or your spouse are an active or honorably
                            discharded veteran of the United States Armed
                            Forces.
                        </li>,
                    );
                } else if (req.type == 'Blind Requirement') {
                    if (!exemption_types['Blind']) {
                        exemption_types['Blind'] = true;
                        exemption_questions.push(EXEMPTION_LOOKUP['Blind']);
                    }
                    if (
                        this.state.qualify_for_exemptions &&
                        this.state.qualify_for_exemptions['blind'] == false
                    ) {
                        qualify = false;
                    }
                    requirement_list.push(
                        <li>
                            You or your spouse have been determined to be blind
                            under the rules of the Social Security
                            Administration
                        </li>,
                    );
                } else if (req.type == 'Widow Requirement') {
                    if (!exemption_types['Widow']) {
                        exemption_types['Widow'] = true;
                        exemption_questions.push(EXEMPTION_LOOKUP['Widow']);
                    }
                    if (
                        this.state.qualify_for_exemptions &&
                        this.state.qualify_for_exemptions['widow'] == false
                    ) {
                        console.log(
                            'Failed Widow',
                            this.state.qualify_for_exemptions['widow'],
                            req.value,
                        );
                        qualify = false;
                    }
                    requirement_list.push(
                        <li>
                            You are the surviving spouse of a deceased person
                            who was receiving an exemption at the time of death.
                        </li>,
                    );
                } else if (req.type == 'Home Ownership Requirement') {
                    let home_ownership_length =
                        (new Date() - new Date('1992-12-11')) /
                        (24 * 60 * 60 * 365 * 1000);
                    if (!exemption_types['Home Ownership']) {
                        exemption_types['Home Ownership'] = true;
                        //exemption_questions.push(
                        //    EXEMPTION_LOOKUP['Home Ownership'],
                        //);
                    }
                    if (
                        this.state.qualify_for_exemptions &&
                        home_ownership_length < req.value
                    ) {
                        console.log(
                            'Failed Home Ownership',
                            home_ownership_length,
                            req.value,
                        );
                        qualify = false;
                    }
                    requirement_list.push(
                        <li>
                            You have owned and lived in your house for{' '}
                            {req.value} years.
                        </li>,
                    );
                }
            }

            let qualify_display = (
                <h5>
                    <i class="bi bi-x" style={{ color: 'red' }}></i>You don't
                    qualify.
                </h5>
            );
            let qualify_buttons = (
                <Button type="dark" onClick={this.clear_form}>
                    I think I qualify. Re-enter my info.
                </Button>
            );
            if (qualify) {
                qualify_display = (
                    <h5>
                        <i class="bi bi-check" style={{ color: 'green' }}></i>
                        You qualify!
                    </h5>
                );
                qualify_buttons = [
                    <Button
                        type="success"
                        onClick={() => this.props.want_to_file(exemption.name)}
                    >
                        File For The Exemption
                    </Button>,
                ];
            }

            let exemption_display = (
                <div className="card col-6" style={{ padding: '10px' }}>
                    <h4>{exemption.name}</h4>
                    <p>
                        Potential Savings Per Year:{' '}
                        <strong className="gradient-color">
                            {exemption.max_value.toLocaleString(
                                'en-US',
                                DOLLAR_STYLING,
                            )}
                        </strong>
                    </p>
                    {qualify_display}
                    <p>Requirements:</p>
                    <ul>{requirement_list}</ul>
                    {qualify_buttons}
                </div>
            );

            exemption_list.push(exemption_display);
        }

        let qualified_exemption_display = (
            <div className="row" style={{ padding: '10px' }}>
                <p>
                    The following are property exemptions within {address_state}{' '}
                    that you may qualify for.
                </p>

                {exemption_list.length > 0 ? exemption_list : no_exemptions}
            </div>
        );

        let exemption_form = null;
        if (
            window.secret_react_state.user &&
            window.secret_react_state.user.id
        ) {
            exemption_form = (
                <Form
                    auto_set_global_state={true}
                    set_global_state={this.submit_demographics}
                >
                    {exemption_questions}
                </Form>
            );
        }

        if (this.state.qualify_for_exemptions) {
            exemption_form = <div className="row">{exemption_list}</div>;
        }

        let exemption_form_display = (
            <div>
                {exemption_form}

                <div
                    style={{
                        textAlign: 'center',
                        padding: '40px 0px 20px 0px',
                    }}
                >
                    <h5
                        style={{
                            display: 'inline-block',
                            paddingRight: '10px',
                        }}
                    >
                        Based on the information you submit, you can save $
                        {total_exemption_value}
                    </h5>
                    <UnlockButton style={{ verticalAlign: 'bottom' }} />
                </div>
            </div>
        );

        return (
            <div>
                <div className="slide-header">
                    <h4>Additional Exemptions</h4>
                    <h6>
                        The following are property exemptions within{' '}
                        {address_state} that you may qualify for
                    </h6>
                </div>
                <div
                    className="simple-card gradient-border"
                    style={{ borderRadius: '16px' }}
                >
                    <div
                        className="gradient-border-content"
                        style={{ borderRadius: '16px', padding: '20px 10px' }}
                    >
                        {this.state.submitted
                            ? qualified_exemption_display
                            : exemption_form_display}{' '}
                    </div>
                </div>
            </div>
        );
    }
}
