import React, { Component } from 'react';
import { TextInput, Select, Image } from 'library';

class Modal extends Component {
    static component_name = 'EmptyModal';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label="class" name="className" default="" />,
                <Select label="show" name="show" boolean />,
            ],
            can_have_children: true,
        };

        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    render() {
        const backgroundStyle = {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0px',
            left: '0px',
            zIndex: '1',
            margin: 'auto',
        };

        const height = window.outerHeight - 150;

        const modalStyle = {
            border: 'none',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
        };

        let modalClass = 'modal fade';
        if (this.props.show === true) {
            modalClass += ' show';
            modalStyle.display = 'block';
        }

        const floating_close_style = {
            position: 'absolute',
            top: '6px',
            right: '6px',
            margin: '0px',
            minWidth: '0px',
            borderRadius: '4px',
            color: 'white',
            background: '#FC7F53',
            padding: '10px',
            lineHeight: '15px',
        };

        let floating_close = (
            <div onClick={this.onHide} style={floating_close_style}>
                <i class="bi bi-x" css={{ width: '15px' }} />
            </div>
        );

        let content_style = Object.assign(
            {},
            {
                overflow: 'auto',
                borderRadius: '32px',
            },
            this.props.style,
        );

        return (
            <div
                className={modalClass}
                tabIndex="-1"
                role="dialog"
                style={modalStyle}
            >
                <div
                    onClick={this.onHide}
                    className="gradient-background-light"
                    style={backgroundStyle}
                />
                <div
                    className="modal-dialog"
                    role="document"
                    style={{
                        zIndex: '10',
                        maxWidth: '2000px',
                        margin: '0px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        height: '100%',
                        flexWrap: 'wrap',
                    }}
                >
                    <div
                        className="modal-content gradient-border"
                        style={content_style}
                    >
                        <div
                            className="gradient-border-content"
                            style={{
                                borderRadius: '32px',
                                padding: '30px',
                            }}
                        >
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
