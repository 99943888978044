import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { DOLLAR_STYLING } from 'constants';
import { ajax_wrapper, get_global_state } from 'functions';

import { Loading, Container, Button, Modal } from 'library';
import { Exemptions, PropertyDetails, Comparables } from 'components';

export default class PropertyOpportunities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property: {},
            exemptions: [],
            comparables: [],
            loaded: false,
            qualify_for_exemptions: null,
            error: null,
            filing: false,
            open_filing_options: false,
            filing_options: null,
        };

        this.get_property = this.get_property.bind(this);
        this.load_property = this.load_property.bind(this);
        this.submit_info = this.submit_info.bind(this);
        this.toggle_filing_options = this.toggle_filing_options.bind(this);
        this.want_to_file = this.want_to_file.bind(this);
    }

    componentDidMount() {
        this.get_property();
    }

    get_property = () => {
        let params = get_global_state()['params'];
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper(
            'GET',
            '/api/property_opportunities/',
            { property_id: params['property_id'] },
            this.load_property,
        );
    };

    load_property = (data) => {
        data['loaded'] = true;
        this.setState(data);

        if (!this.initializedAds) {
            setTimeout(() => {
                window.initializeMyFinance(true);
                this.initializedAds = true;
            }, 500);
        }
    };

    submit_info = (data) => {
        ajax_wrapper(
            'POST',
            '/user/submit_demographics/',
            { data: data },
            console.log,
        );
        this.setState({ qualify_for_exemptions: data });
    };

    toggle_filing_options = (exemption) => {
        console.log('Toggle Filing Options', exemption);
        this.setState({ filing_options: exemption, open_filing_options: true });
    };

    want_to_file = (exemption, diy) => {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'POST',
            '/api/want_to_file/',
            {
                exemption_type: exemption,
                diy: diy,
                property_id: params['property_id'],
            },
            () =>
                this.setState({
                    filing: true,
                    filing_options: null,
                    open_filing_options: false,
                }),
        );
    };

    render() {
        if (this.state.error) {
            return (
                <Container>
                    <p>
                        We're sorry. The analysis we do requires a bit more
                        information. We'll follow up with you in an email
                        shortly.
                    </p>
                </Container>
            );
        }

        if (this.state.loaded) {
            //console.log("State", this.state)
            let property = this.state.property;
            let property_id = property.id;
            //console.log("Property", property)
            let address = property.propertyInfo.address;

            let comparables = this.state.comparables;
            let average_savings = 0;
            let i = 0;
            let savings = (
                <span>
                    No Current Comparables, we'll be in touch when we found
                    something.
                </span>
            );

            for (let comp of comparables) {
                average_savings += comp.savings;
                i++;
                if (i == 3) {
                    break;
                }
            }
            if (i != 0) {
                average_savings = average_savings / i;
                if (average_savings <= 0) {
                    savings = (
                        <span>
                            $0, we'll follow up when there is an opportunity.
                            Check below for other ways to save.
                        </span>
                    );
                } else {
                    savings = (
                        <span>
                            {average_savings.toLocaleString(
                                'en-US',
                                DOLLAR_STYLING,
                            )}
                        </span>
                    );
                }
            }

            if (!window.secret_react_state['user']) {
                savings = null;
            }

            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{address.label} Property Tax Comparison</title>
                        <meta
                            name="description"
                            content={
                                'A comparison of ' +
                                address.label +
                                ' and similar properties and their property tax rates.'
                            }
                        />
                    </Helmet>

                    <Modal
                        show={this.state.open_filing_options}
                        style={{ maxWidth: '600px', padding: '20px' }}
                        onHide={() =>
                            this.setState({
                                filing_options: null,
                                open_filing_options: false,
                            })
                        }
                    >
                        <div style={{ textAlign: 'center' }}>
                            <h1
                                style={{ fontSize: '48px', fontWeight: 'bold' }}
                                className="gradient-color"
                            >
                                Filing For {this.state.filing_options}
                            </h1>
                            <p>Would you rather us file for you?</p>
                            <h4>For $50:</h4>

                            <ul>
                                <li>
                                    We will make sure all the right forms are
                                    completed and sent to the right county
                                    officer.
                                </li>
                                <li>
                                    If for any reason you don't save more than
                                    $50/yr with the exemption, you will get a
                                    full refund.
                                </li>
                            </ul>
                            <Button
                                onClick={() =>
                                    this.want_to_file(
                                        this.state.filing_options,
                                        false,
                                    )
                                }
                                type="success"
                            >
                                File For Me
                            </Button>
                            <p>
                                Or we can give you the instructions for you to
                                file everything on your own.
                            </p>
                            <Button
                                onClick={() =>
                                    this.want_to_file(
                                        this.state.filing_options,
                                        true,
                                    )
                                }
                                type="warning"
                            >
                                File It Myself
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        show={this.state.filing}
                        style={{ maxWidth: '600px', padding: '20px' }}
                        onHide={() => this.setState({ filing: false })}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <h1
                                style={{ fontSize: '48px', fontWeight: 'bold' }}
                                className="gradient-color"
                            >
                                Thanks for choosing HomeBase.
                            </h1>
                            <h5>
                                We'll be in touch over email with the next steps
                                to help you save on your property taxes.
                            </h5>
                        </div>
                        <br />
                    </Modal>

                    <Loading loaded={this.state.loaded}>
                        <Container>
                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: '50px 0px 20px',
                                }}
                            >
                                <span
                                    className="gradient-color"
                                    style={{
                                        fontSize: '84px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Your Property, Your Savings
                                </span>

                                <br />
                                <span
                                    style={{
                                        fontSize: '36px',
                                        fontWeight: '500',
                                        display: 'inline-block',
                                        margin: '0px 45px',
                                    }}
                                >
                                    Discover how much you could save on property
                                    taxes with our smarter, AI-powered
                                    assessment. Start today!
                                </span>
                            </div>
                            <PropertyDetails
                                property={this.state.property}
                                savings={savings}
                            />
                        </Container>

                        <div className="gradient-background-light-center">
                            <Container>
                                <Comparables
                                    property={this.state.property}
                                    comparables={this.state.comparables}
                                    want_to_file={this.toggle_filing_options}
                                    average_savings={average_savings}
                                />
                            </Container>
                        </div>

                        <Container style={{ paddingBottom: '40px' }}>
                            <Exemptions
                                want_to_file={this.toggle_filing_options}
                                property={this.state.property}
                                exemptions={this.state.exemptions}
                            />
                        </Container>
                        <Container style={{ paddingBottom: '40px' }}>
                            <div className="slide-header">
                                <h4>Other Ways To Save</h4>
                                <h6>
                                    The following are other ways to save money
                                    on your yearly home expenses.
                                </h6>
                            </div>
                            <div
                                className="simple-card"
                                style={{ padding: '10px', marginTop: '20px' }}
                                id="mortgages"
                            >
                                <h6>Mortgage</h6>
                                <div>
                                    Below, there are other mortgage offers that
                                    you could re-finance and potentially save
                                    $100s per month depending on your current
                                    mortgage. Check them out.
                                </div>
                                <div
                                    class="myFinance-widget"
                                    data-ad-id="16d38958-a765-4eed-bb76-88443c3204c0"
                                    data-campaign="homebase-home-equity-multi"
                                    data-sub-id={property_id}
                                ></div>
                            </div>

                            <div
                                className="simple-card"
                                style={{ padding: '10px', marginTop: '20px' }}
                            >
                                <h6>Insurance</h6>
                                <div>
                                    Another cause of high home costs is
                                    insurance. Check and see if you can get a
                                    better rate today.
                                </div>
                                <div
                                    class="myFinance-widget"
                                    data-ad-id="13647411-2e7e-45bc-829d-ce6630321fe6"
                                    data-campaign="homebase-home-insurance-multi"
                                    data-sub-id={property_id}
                                ></div>
                            </div>
                        </Container>
                    </Loading>
                </div>
            );
        } else {
            return <Loading loaded={this.state.loaded} />;
        }
    }
}
