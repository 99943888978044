import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert, Modal } from 'library';
import {Helmet} from "react-helmet";
import { DOLLAR_STYLING } from 'constants';

class Exemption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filed: false,
        };
        this.want_to_file = this.want_to_file.bind(this);
    }

    want_to_file(exemption, diy) {
        let params = get_global_state()['params'];

        let address = `${params['address']}, ${params['city']}, ${params['state']} ${params['zip']}`

        ajax_wrapper('POST', '/api/want_to_file/', {'exemption_type': exemption, 'diy': diy, 
            'email':params['email'], 'address': address }, () => this.setState({filing: true}))

        this.setState({filed: true})
    }

    render() {
        let exemption = this.props.exemption;

        if (this.state.filed) {
            return (
                <div style={{
                    position: "relative",
                    background: "white",
                    width: "90%",
                    maxWidth: "500px",
                    margin: "50px auto",
                    padding: "30px",
                }}>
                    <h4 style={{
                    color: "#2c3e50",
                    fontSize: "24px",
                    margin: "0 0 20px 0"
                    }}>Thank you for your interest in filing</h4>
                    <p style={{
                    color: "#666",
                    fontSize: "16px",
                    margin: 0
                    }}>We'll follow up shortly with next steps.</p>
                </div>
            )
        }
        else {
            return (
            <div style={{
                backgroundColor: "#f8f9fa",
                borderRadius: "12px",
                padding: "25px",
                border: "1px solid #eee"
            }}>
                <h4 style={{
                color: "#2c3e50",
                fontSize: "22px",
                margin: "0 0 20px 0",
                fontWeight: 600
                }}>
                {exemption.name}
                </h4>
                
                <div style={{ marginBottom: "25px" }}>
                <span style={{
                    color: "#666",
                    fontSize: "16px"
                }}>
                    Potential Savings Per Year:
                </span>
                <span style={{
                    color: "#2ecc71",
                    fontSize: "24px",
                    fontWeight: 600,
                    marginLeft: "10px"
                }}>
                    {exemption.max_value.toLocaleString('en-US', DOLLAR_STYLING)}
                </span>
                </div>
    
                {/* Buttons Container */}
                <div style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap"
                }}>
                <button style={{
                    padding: "15px 25px",
                    background: "linear-gradient(135deg, #2ecc71 0%, #27ae60 100%)",
                    color: "white",
                    border: "none",
                    borderRadius: "8px",
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    flex: 1,
                    minWidth: "200px",
                    boxShadow: "0 4px 15px rgba(46,204,113,0.2)"
                }} onClick={() => this.want_to_file(exemption.name, false)}>
                    File My Appeal For Me ($50)
                </button>
                
                <button style={{
                    padding: "15px 25px",
                    background: "white",
                    color: "#2ecc71",
                    border: "2px solid #2ecc71",
                    borderRadius: "8px",
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer",
                    transition: "transform 0.2s, background-color 0.2s",
                    flex: 1,
                    minWidth: "200px"
                }} onClick={() => this.want_to_file(exemption.name, true)}>
                    I'll File My Own Appeal (~45 min), Send Me The Instructions
                </button>
                </div>
            </div>
            )
        }
    }


}

export default class ElderlyExemptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exemptions: [],
            loaded: false,
            state: '',
        };

        this.load_exemptions = this.load_exemptions.bind(this);
        this.get_state = this.get_state.bind(this);
    }

    componentDidMount() {

        let params = get_global_state()['params'];
        ajax_wrapper('GET','/api/elderly_exemptions/', {'state': this.get_state(params['zip'])}, this.load_exemptions)
        this.get_state(params['zip'])

    }

    get_state(zip) {

        /* Ensure param is a string to prevent unpredictable parsing results */
        if (typeof zip !== 'string') {
            console.error('Must pass the zipcode as a string.');
            return;
        }
      
        /* Ensure we have exactly 5 characters to parse */
        if (zip.length !== 5) {
            console.error('Must pass a 5-digit zipcode.');
            return;
        }
      
        /* Ensure we don't parse strings starting with 0 as octal values */
        const zipcode = parseInt(zip, 10);
      
        let st;
        let state;
      
        /* Code cases alphabetized by state */
        if (zipcode >= 35000 && zipcode <= 36999) {
            st = 'AL';
            state = 'Alabama';
        } else if (zipcode >= 99500 && zipcode <= 99999) {
            st = 'AK';
            state = 'Alaska';
        } else if (zipcode >= 85000 && zipcode <= 86999) {
            st = 'AZ';
            state = 'Arizona';
        } else if (zipcode >= 71600 && zipcode <= 72999) {
            st = 'AR';
            state = 'Arkansas';
        } else if (zipcode >= 90000 && zipcode <= 96699) {
            st = 'CA';
            state = 'California';
        } else if (zipcode >= 80000 && zipcode <= 81999) {
            st = 'CO';
            state = 'Colorado';
        } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
            st = 'CT';
            state = 'Connecticut';
        } else if (zipcode >= 19700 && zipcode <= 19999) {
            st = 'DE';
            state = 'Delaware';
        } else if (zipcode >= 32000 && zipcode <= 34999) {
            st = 'FL';
            state = 'Florida';
        } else if ( (zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999) ) {
            st = 'GA';
            state = 'Georgia';
        } else if (zipcode >= 96700 && zipcode <= 96999) {
            st = 'HI';
            state = 'Hawaii';
        } else if (zipcode >= 83200 && zipcode <= 83999 && zipcode != 83414) {
            st = 'ID';
            state = 'Idaho';
        } else if (zipcode >= 60000 && zipcode <= 62999) {
            st = 'IL';
            state = 'Illinois';
        } else if (zipcode >= 46000 && zipcode <= 47999) {
            st = 'IN';
            state = 'Indiana';
        } else if (zipcode >= 50000 && zipcode <= 52999) {
            st = 'IA';
            state = 'Iowa';
        } else if (zipcode >= 66000 && zipcode <= 67999) {
            st = 'KS';
            state = 'Kansas';
        } else if (zipcode >= 40000 && zipcode <= 42999) {
            st = 'KY';
            state = 'Kentucky';
        } else if (zipcode >= 70000 && zipcode <= 71599) {
            st = 'LA';
            state = 'Louisiana';
        } else if (zipcode >= 3900 && zipcode <= 4999) {
            st = 'ME';
            state = 'Maine';
        } else if (zipcode >= 20600 && zipcode <= 21999) {
            st = 'MD';
            state = 'Maryland';
        } else if ( (zipcode >= 1000 && zipcode <= 2799) || (zipcode == 5501) || (zipcode == 5544 ) ) {
            st = 'MA';
            state = 'Massachusetts';
        } else if (zipcode >= 48000 && zipcode <= 49999) {
            st = 'MI';
            state = 'Michigan';
        } else if (zipcode >= 55000 && zipcode <= 56899) {
            st = 'MN';
            state = 'Minnesota';
        } else if (zipcode >= 38600 && zipcode <= 39999) {
            st = 'MS';
            state = 'Mississippi';
        } else if (zipcode >= 63000 && zipcode <= 65999) {
            st = 'MO';
            state = 'Missouri';
        } else if (zipcode >= 59000 && zipcode <= 59999) {
            st = 'MT';
            state = 'Montana';
        } else if (zipcode >= 27000 && zipcode <= 28999) {
            st = 'NC';
            state = 'North Carolina';
        } else if (zipcode >= 58000 && zipcode <= 58999) {
            st = 'ND';
            state = 'North Dakota';
        } else if (zipcode >= 68000 && zipcode <= 69999) {
            st = 'NE';
            state = 'Nebraska';
        } else if (zipcode >= 88900 && zipcode <= 89999) {
            st = 'NV';
            state = 'Nevada';
        } else if (zipcode >= 3000 && zipcode <= 3899) {
            st = 'NH';
            state = 'New Hampshire';
        } else if (zipcode >= 7000 && zipcode <= 8999) {
            st = 'NJ';
            state = 'New Jersey';
        } else if (zipcode >= 87000 && zipcode <= 88499) {
            st = 'NM';
            state = 'New Mexico';
        } else if ( (zipcode >= 10000 && zipcode <= 14999) || (zipcode == 6390) || (zipcode == 501) || (zipcode == 544) ) {
            st = 'NY';
            state = 'New York';
        } else if (zipcode >= 43000 && zipcode <= 45999) {
            st = 'OH';
            state = 'Ohio';
        } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999) ) {
            st = 'OK';
            state = 'Oklahoma';
        } else if (zipcode >= 97000 && zipcode <= 97999) {
            st = 'OR';
            state = 'Oregon';
        } else if (zipcode >= 15000 && zipcode <= 19699) {
            st = 'PA';
            state = 'Pennsylvania';
        } else if (zipcode >= 300 && zipcode <= 999) {
            st = 'PR';
            state = 'Puerto Rico';
        } else if (zipcode >= 2800 && zipcode <= 2999) {
            st = 'RI';
            state = 'Rhode Island';
        } else if (zipcode >= 29000 && zipcode <= 29999) {
            st = 'SC';
            state = 'South Carolina';
        } else if (zipcode >= 57000 && zipcode <= 57999) {
            st = 'SD';
            state = 'South Dakota';
        } else if (zipcode >= 37000 && zipcode <= 38599) {
            st = 'TN';
            state = 'Tennessee';
        } else if ( (zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) ||  (zipcode >= 88500 && zipcode <= 88599) ) {
            st = 'TX';
            state = 'Texas';
        } else if (zipcode >= 84000 && zipcode <= 84999) {
            st = 'UT';
            state = 'Utah';
        } else if (zipcode >= 5000 && zipcode <= 5999) {
            st = 'VT';
            state = 'Vermont';
        } else if ( (zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode == 20598) ) {
            st = 'VA';
            state = 'Virginia';
        } else if ( (zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999) ) {
            st = 'DC';
            state = 'Washington DC';
        } else if (zipcode >= 98000 && zipcode <= 99499) {
            st = 'WA';
            state = 'Washington';
        } else if (zipcode >= 24700 && zipcode <= 26999) {
            st = 'WV';
            state = 'West Virginia';
        } else if (zipcode >= 53000 && zipcode <= 54999) {
            st = 'WI';
            state = 'Wisconsin';
        } else if ( (zipcode >= 82000 && zipcode <= 83199) || zipcode == 83414 ) {
            st = 'WY';
            state = 'Wyoming';
        } else {
            st = 'none';
            state = 'none';
            console.log('No state found matching', zipcode);
        }
      
        /* Return `state` for full name or `st` for postal abbreviation */
        this.setState({state: state});
        return st;
      }

    load_exemptions(data) { 
        this.setState({exemptions: data['exemptions'], loaded: true})
    }

    render() {
        
        let exemption_list = []
        for (let exemption of this.state.exemptions) {
            exemption_list.push(<Exemption exemption={exemption} />)
        }

        let qualify =  `${this.state.state} has the following exemptions you may qualify for:`
        if (exemption_list.length === 0) {
            qualify = "Unfortunately, you do not qualify for any exemptions at this time."
        }

        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta name="description" content="An easy way to find where you are overpaying on property expenses." />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                <div style={{backgroundColor: "#f8f9fa",
                minHeight: "100vh",
                padding: "40px 20px"
                }}>
                <div style={{
                    maxWidth: "800px",
                    margin: "0 auto",
                    position: "relative"
                }}>
                    {/* Main Container */}
                    <div style={{
                    backgroundColor: "white",
                    borderRadius: "16px",
                    boxShadow: "0 10px 30px rgba(0,0,0,0.08)",
                    overflow: "hidden"
                    }}>
                    {/* Header */}
                    <div style={{
                        background: "linear-gradient(135deg, #4a90e2 0%, #357abd 100%)",
                        padding: "30px",
                        color: "white"
                    }}>
                        <h1 style={{
                        fontSize: "28px",
                        margin: 0,
                        fontWeight: 600,
                        letterSpacing: "-0.5px"
                        }}>
                        {qualify}
                        </h1>
                    </div>

                            <div style={{ padding: "30px" }}>
                             {exemption_list}
                            </div>
                            
                         </div>
                        </div>
                    </div>
               
                </Loading>
            </Container>
        );
    }
}
