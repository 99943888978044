import React, { Component } from 'react';

import { Image, Button, Modal } from 'library';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';
import {ContactUs} from 'pages';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            contact_us: false,
        };
    }

    render() {
        let checker = new UserValidator();
        let is_logged_in = checker.logged_id();
        let is_staff = checker.is_staff();

        let items = [];

        if (!is_logged_in) {
            /*items.push(
                <Button href="/login/" className="nav-item">
                    Login
                </Button>,
            );
            items.push(
                <Button href="/about/" className="nav-item">
                    About
                </Button>,
            );
            items.push(
                <Button href="/contact/" className="nav-item">
                    Contact
                </Button>,
            );
            items.push(
                <Button
                    type="dark"
                    style={{ borderRadius: '100px' }}
                    href="/signup/"
                >
                    Join For Free
                </Button>,
            );*/
            
        }

        if (is_staff) {
            // Add staff routes to header

            items.push(
                <Button
                    className="nav-item"
                    href="/admin/exemption_list/"
                    style={{ marginRight: '5px' }}
                >
                    Exemption List
                </Button>,
            );
        }

        if (is_logged_in) {
            // Add authed routes to header
            items.push(
                <Button className="nav-item" href="/address_search/">
                    Property Search
                </Button>,
            );
            items.push(
                <Button className="nav-item" href="/logout/">
                    Logout
                </Button>,
            );
        }

        items.push(
            <Button
                type="dark"
                style={{ borderRadius: '100px' }}
                onClick={() => this.setState({contact_us: true})}
            >
                Contact Us
            </Button>,
        );

        return (
            <div className="container">
                <header className="d-flex justify-content-center row">
                    <div
                        className="col-xxl-6 col-xl-8 col-12"
                        style={{
                            border: 'thin solid #d9d9d9',
                            borderRadius: '100px',
                            background: 'white',
                            padding: '12px 20px',
                            margin: '20px 0px',
                        }}
                    >
                        <div style={{ float: 'right' }}>{items}</div>
                        <Modal style={{maxWidth:'600px'}} show={this.state.contact_us} onHide={() => this.setState({contact_us: false})}>
                            <ContactUs />
                        </Modal>
                        <a href="/">
                            <img
                                src="/static/images/logo.svg"
                                style={{ height: '38px', padding: '10px 20px' }}
                            ></img>
                        </a>
                    </div>
                </header>
            </div>
        );
    }
}

export default Header;
