import React, { Component } from 'react';

export default class PrettyAddress extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let property = this.props.property;

        if (this.props.small) {
            return (
                <div style={{ marginBottom: '20px', marginTop: '14px' }}>
                    <h4 style={{ color: 'black' }}>{property.address}</h4>
                    <div style={{ color: '#656565', fontWeight: '400' }}>
                        {property.city}, {property.state} {property.zip}-
                        {property.zip4}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ marginBottom: '20px' }}>
                    <h1>{property.address}</h1>
                    <h6 style={{ color: '#656565', fontWeight: '400' }}>
                        {property.city}, {property.state} {property.zip}-
                        {property.zip4}
                    </h6>
                </div>
            );
        }
    }
}
