import React, { Component } from 'react';
import { ajax_wrapper } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert } from 'library';
import { Address, AssessedValue, ComparableCard } from 'pages';
import {
    APIProvider,
    Map,
    Marker,
    InfoWindow,
} from '@vis.gl/react-google-maps';
import { Helmet } from 'react-helmet';

class AddressPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            textColor: '#666',
        };
    }

    render() {
        let property = this.props.property;

        let style = { cursor: 'pointer', padding: '5px' };

        if (property.address == 'None Found') {
            return (
                <div className="google-address-result" style={style}>
                    No properties found. Which means you're paying the right
                    amount for your property tax! Keep it that way with instant
                    assessment alerts. <a href="/sign_up">Sign up today</a>
                </div>
            );
        } else {
            let display_address = property.address;

            if (property.address2) {
                display_address += ' ' + property.address2;
            }

            display_address +=
                ', ' +
                property.city +
                ', ' +
                property.state +
                ' ' +
                property.zipcode;

            return (
                <div
                    onClick={() =>
                        this.props.select_property(this.props.property)
                    }
                    className="google-address-result"
                    style={style}
                >
                    {display_address}
                </div>
            );
        }
    }
}

class PropertyMarker extends Component {
    constructor(props) {
        super(props);

        this.select_property = this.select_property.bind(this);
        this.deselect_property = this.deselect_property.bind(this);
    }

    select_property() {
        this.props.select_property(this.props.property);
    }

    deselect_property() {
        this.props.select_property({});
    }

    render() {
        let property = this.props.property;
        let display = null;
        if (this.props.selected_property.id == this.props.property.id) {
            let selected_property = this.props.selected_property;
            let savings =
                (selected_property.current_assessed_value -
                    selected_property.potential_assessment_value) *
                0.0224;

            display = (
                <InfoWindow
                    style={{ width: '300px' }}
                    position={{
                        lat: property.latitude,
                        lng: property.longitude,
                    }}
                    onCloseClick={this.deselect_property}
                >
                    <Address
                        property={this.props.property}
                        vertical={true}
                        small={true}
                    />
                    <Alert type="info">
                        <h6 style={{ marginBottom: '8px' }}>
                            {`You could save`}
                            <br />
                            <b
                                style={{ fontSize: '24px' }}
                            >{`$${savings.toFixed(0)}`}</b>
                            {` per year!`}
                        </h6>
                        <Button
                            type="success"
                            href={
                                '/property_comparison/' + selected_property.id
                            }
                            target="_blank"
                            style={{ fontSize: '20px' }}
                        >
                            Learn More
                        </Button>
                    </Alert>
                </InfoWindow>
            );
        }

        return (
            <div>
                <Marker
                    key={property.id}
                    position={{
                        lat: property.latitude,
                        lng: property.longitude,
                    }}
                    onClick={this.select_property}
                />
                {display}
            </div>
        );
    }
}

export default class TaxMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            properties: [],
            selected_property: {},
            potential_addresses: [],
            loaded: false,
            windowWidth: 0,
            windowHeight: 0,
            show_potential_address: true,
            map_boundaries: {
                east: -74.0147882138672,
                north: 40.746326787387034,
                south: 40.704306779998305,
                west: -74.12602478613283,
            },
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.get_properties = this.get_properties.bind(this);
        this.load_properties = this.load_properties.bind(this);
        this.select_property = this.select_property.bind(this);
        this.search_address = this.search_address.bind(this);

        this.focus_input = this.focus_input.bind(this);
        this.blur_input = this.blur_input.bind(this);
        this.handle_camera_change = this.handle_camera_change.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.get_properties();
    }

    get_properties() {
        //this.setState({'properties': properties, 'loaded':true})
        ajax_wrapper(
            'GET',
            '/api/property_locations/',
            {},
            this.load_properties,
        );
    }

    load_properties(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    select_property(property) {
        if (property.id) {
            //window.gtag('event', 'select_property', {'property_id': property.id});
        } else {
            //window.gtag('event', 'deselect_property', {});
        }

        this.setState({ potential_addresses: [], selected_property: property });
    }

    search_address(name, data) {
        //window.gtag('event', 'search_address', {});
        let address = data['address'];

        if (address) {
            let potential_addresses = [];

            for (let property of this.state.properties) {
                if (
                    property.address
                        .toLowerCase()
                        .includes(address.toLowerCase())
                ) {
                    potential_addresses.push(property);
                }
                if (potential_addresses.length > 5) {
                    break;
                }
            }

            if (potential_addresses.length == 0) {
                potential_addresses.push({ address: 'None Found' });
            }

            this.setState({ potential_addresses: potential_addresses });
        } else {
            this.setState({ potential_addresses: [] });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    focus_input() {
        this.setState({ show_potential_address: true });
    }

    blur_input() {
        //this.setState({show_potential_address: false})
    }

    handle_camera_change(data) {
        //window.gtag('event', 'move_map', {});
        this.setState({ map_boundaries: data['detail']['bounds'] });
    }

    render() {
        let marker_list = [];
        let total_overpayment = 0;

        for (var property of this.state.properties) {
            if (
                property.latitude < this.state.map_boundaries['north'] &&
                property.latitude > this.state.map_boundaries['south'] &&
                property.longitude < this.state.map_boundaries['east'] &&
                property.longitude > this.state.map_boundaries['west']
            ) {
                total_overpayment +=
                    (property.current_assessed_value -
                        property.potential_assessment_value) *
                    0.0224;
            }

            if (marker_list.length <= 100) {
                marker_list.push(
                    <PropertyMarker
                        key={property.id}
                        property={property}
                        select_property={this.select_property}
                        selected_property={this.state.selected_property}
                    />,
                );
            }
        }

        let addresses = [];
        if (this.state.show_potential_address) {
            for (let property of this.state.potential_addresses) {
                addresses.push(
                    <AddressPicker
                        property={property}
                        select_property={this.select_property}
                    />,
                );
            }
        }

        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Jersey City Property Tax Map</title>
                    <meta
                        name="description"
                        content="An interactive map of properties in Jersey City that are overpaying their taxes."
                    />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <div className="simple-card">
                        <h1>Are you overpaying?</h1>
                        <br />
                        <Alert type="danger">
                            <h3 style={{ marginBottom: '0px' }}>
                                There are{' '}
                                <b>
                                    {total_overpayment.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                </b>{' '}
                                in extra taxes on the map right now!
                            </h3>
                        </Alert>
                        <br />
                        <Alert
                            type="info"
                            style={{
                                marginBottom: '0px',
                                borderBottomRightRadius: '0px',
                                borderBottomLeftRadius: '0px',
                            }}
                        >
                            <Form
                                auto_set_global_state={true}
                                set_global_state={this.search_address}
                                global_state_name="address_search"
                            >
                                <h5>
                                    Search Your Address To Find Out If You Are
                                    Overpaying
                                </h5>
                                <TextInput
                                    name="address"
                                    autocomplete={'off'}
                                />

                                <div style={{ position: 'relative' }}>
                                    <div className="google-address-result-container">
                                        {addresses}
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </Alert>

                        <div
                            style={{
                                height: this.state.height - 320 + 'px',
                                width: '100%',
                            }}
                        >
                            <APIProvider
                                apiKey={
                                    'AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU'
                                }
                            >
                                <Map
                                    key={'map'}
                                    defaultZoom={14}
                                    defaultCenter={{
                                        lat: 40.7253201,
                                        lng: -74.0704065,
                                    }}
                                    onCameraChanged={this.handle_camera_change}
                                >
                                    {marker_list}
                                </Map>
                            </APIProvider>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
