import React, { Component } from 'react';
import { DOLLAR_STYLING } from 'constants';
import { format_date } from 'functions';
import PrettyAddress from './pretty_address';

import {
    APIProvider,
    Map,
    AdvancedMarker,
    MapControl,
} from '@vis.gl/react-google-maps';
import { Button } from 'library';
import UnlockButton from 'components/unlock_button';

class ComparableRow extends Component {
    render() {
        let property = this.props.property;
        let comparable = this.props.comparable;

        let latlng = comparable.latitude + ',' + comparable.longitude;

        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';

        let savings = comparable.savings;

        let savings_type = <span>save</span>;
        if (savings < 0) {
            savings_type = <strong style={{'color':'red'}}>lose</strong>;
            savings = Math.abs(savings);
        }


        return (
            <div className="simple-card" style={{ overflow: 'hidden' }}>
                <a
                    href={'/property_opportunities/' + comparable.id}
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                >
                    <div
                        className="background-image"
                        style={{
                            backgroundImage: `url(${img_url})`,
                            backgroundPosition: 'center',
                            margin: '-10px -20px 0px',
                            width: 'calc(100% + 40px)',
                        }}
                    ></div>

                    <PrettyAddress property={comparable.address} small={true} />
                </a>
                <div className="line-item">
                    <label>Distance from you:</label>
                    <span>{comparable.distance}</span>
                </div>
                <div className="page-break"></div>

                <div className="line-item">
                    <label>Sold On:</label>
                    <span>{format_date(comparable.lastSaleDate)}</span>
                </div>
                <div className="line-item">
                    <label>Sold For:</label>
                    <span>
                        {parseFloat(comparable.lastSaleAmount).toLocaleString(
                            'en-US',
                            DOLLAR_STYLING,
                        )}
                    </span>
                </div>
                <div className="page-break"></div>

                <div className="line-item">
                    <label>Living:</label>
                    <span>{comparable.squareFeet}</span>
                </div>
                <div className="line-item">
                    <label>Acreage:</label>
                    <span>{comparable.lotSquareFeet}</span>
                </div>

                <div
                    style={{
                        display: 'inline-block',
                        padding: '0px 6px',
                    }}
                >
                    <div
                        style={{
                            fontSize: '1.6rem',
                            fontWeight: '500',
                        }}
                    >
                        {comparable.bedrooms}
                    </div>
                    <div>Beds</div>
                </div>
                <div
                    style={{
                        display: 'inline-block',
                        padding: '0px 6px',
                    }}
                >
                    <div
                        style={{
                            fontSize: '1.6rem',
                            fontWeight: '500',
                        }}
                    >
                        {comparable.bathrooms}
                    </div>
                    <div>Baths</div>
                </div>

                <div className="page-break"></div>

                <div style={{ textAlign: 'center' }}>
                    <h5>
                        If your property were taxed the same way, you’d {savings_type} <span className='gradient-color'>{savings.toLocaleString('en-US', DOLLAR_STYLING)}</span> per year.
                    </h5>
                    <UnlockButton />
                </div>
            </div>
        );
    }
}

class PropertyMarker extends Component {
    render() {
        let property = this.props.property;

        return (
            <AdvancedMarker
                key={property.id}
                position={{
                    lat: property.latitude,
                    lng: property.longitude,
                }}
            >
                <Button
                    type="dark"
                    style={{ borderRadius: '100px' }}
                >{`${property.distance} miles away`}</Button>
            </AdvancedMarker>
        );
    }
}

export default class Comparables extends Component {
    render() {
        let property = this.props.property;

        let first_comparable = null;
        let comparable_list = [];
        let marker_list = [];
        let lats = [property.propertyInfo.latitude];
        let lngs = [property.propertyInfo.longitude];

        let error = null;
        if (this.props.comparables.length === 0) {
            error = <h4>No comparables found. We'll reach out if there is an opportunity to appeal in the future. Check below for other potential savings.</h4>;
        }
        
        let appeal = null;
        if (this.props.comparables.length > 0 && this.props.average_savings > 0) {
            appeal = <Button
                className="gradient-border"
                {...this.props}
                onClick={() => this.props.want_to_file('Property Tax Appeal')}
            >
                <div
                    className="gradient-border-content"
                    style={{ fontWeight: 500 }}
                >
                    {'File An Appeal'}
                </div>
            </Button>
        }

        for (let comparable of this.props.comparables) {

            if (!first_comparable) {
                first_comparable = (
                    <div className="col-lg-4 col-12">
                        <ComparableRow
                            comparable={comparable}
                            property={this.props.property}
                        />
                    </div>
                );
            } else {
                comparable_list.push(
                    <div className="col-lg-4 col-12">
                        <ComparableRow
                            small={true}
                            comparable={comparable}
                            property={this.props.property}
                        />
                    </div>,
                );
            }

            marker_list.push(
                <PropertyMarker key={comparable.id} property={comparable} />,
            );

            lats.push(comparable.latitude);
            lngs.push(comparable.longitude);

            if (comparable_list.length >= 3) {
                break;
            }
        }

        let bounds_diff = 0.005;
        let map = (
            <div className="col-lg-8 col-12">
                <div
                    className="simple-card"
                    style={{
                        padding: '0px',
                        overflow: 'hidden',
                        width: 'calc(100% - 20px)',
                        height: 'calc(100% - 20px)',
                    }}
                >
                    <APIProvider
                        apiKey={'AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU'}
                    >
                        <Map
                            mapId="DEMO_MAP_ID"
                            key={'comparables_map'}
                            defaultCenter={{
                                lat: property.propertyInfo.latitude,
                                lng: property.propertyInfo.longitude,
                            }}
                            defaultBounds={{
                                east: Math.max.apply(Math, lngs) + bounds_diff,
                                west: Math.min.apply(Math, lngs) - bounds_diff,
                                north: Math.max.apply(Math, lats) + bounds_diff,
                                south: Math.min.apply(Math, lats) - bounds_diff,
                            }}
                        >
                            <AdvancedMarker
                                key={property.id}
                                position={{
                                    lat: property.propertyInfo.latitude,
                                    lng: property.propertyInfo.longitude,
                                }}
                            >
                                <div
                                    className="gradient-border"
                                    style={{ padding: '4px' }}
                                >
                                    <div
                                        className="gradient-border-content"
                                        style={{ padding: '4px 6px' }}
                                    >
                                        <img
                                            src="/static/images/house.svg"
                                            style={{
                                                height: '18px',
                                                marginRight: '5px',
                                            }}
                                        />
                                        <span style={{ fontSize: '14px' }}>
                                            Your Home
                                        </span>
                                    </div>
                                </div>
                            </AdvancedMarker>
                            {marker_list}
                        </Map>
                    </APIProvider>
                </div>
            </div>
        );

        let unlock = null;
        if (!window.secret_react_state['user']) {
            unlock = <div><h6
                    style={{ display: 'inline-block', paddingRight: '4px' }}
                >
                    Unlock to view more similar taxed homes
                </h6>
                <UnlockButton />
            </div>;
        }

        return (
            <div
                style={{
                    paddingBottom: '40px',
                }}
            >
                <div className="slide-header">
                    <h4>Similar Homes Nearby</h4>
                    <h6>
                        These are similar houses in your area to see if you have
                        been taxed unfairly.
                    </h6>

                    {error}
                </div>
                <div className="row">
                    {first_comparable}
                    {map}
                    {comparable_list}
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        padding: '40px 0px 20px 0px',
                    }}
                >
                    {unlock}
                    {appeal}
                </div>
            </div>
        );
    }
}
