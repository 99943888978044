import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button, Form, TextInput, Alert, Modal } from 'library';
import { Address, AssessedValue, ComparableCard } from 'pages';
import {
    APIProvider,
    Map,
    Marker,
    InfoWindow,
} from '@vis.gl/react-google-maps';
import {Helmet} from "react-helmet";

const GOOGLE_CLIENT_ID =
    '378099360565-n2jb6ia8u7gr4s85u1d8l0ckp01mfq0e.apps.googleusercontent.com';

class AddressPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: 'white',
            textColor: '#666',
        };
    }

    render() {
        let property = this.props.property;

        let style = { cursor: 'pointer', padding: '5px' };

        if (property.address == 'None Found') {
            return (
                <div className="google-address-result" style={style}>
                    No properties found. Which means you're paying the right
                    amount for your property tax! Keep it that way with instant
                    assessment alerts. <a href="/sign_up">Sign up today</a>
                </div>
            );
        } else {
            let display_address = property.address;

            return (
                <div
                    onClick={() =>
                        this.props.select_property(this.props.property)
                    }
                    className="google-address-result"
                    style={style}
                >
                    {display_address}
                </div>
            );
        }
    }
}


export default class AddressSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            potential_addresses: [],
            loaded: false,
            windowWidth: 0,
            windowHeight: 0,
            show_potential_address: true,
            open_modal: false,
            property: {address:''}
        };

        this.search_address = this.search_address.bind(this);
        this.load_addresses = this.load_addresses.bind(this);
        this.select_property = this.select_property.bind(this);

        this.focus_input = this.focus_input.bind(this);
        this.blur_input = this.blur_input.bind(this);

        this.submit_email = this.submit_email.bind(this);
        this.sign_up_callback = this.sign_up_callback.bind(this);

        this.google_login = this.google_login.bind(this);
        this.google_error = this.google_error.bind(this);

        this.check_address = this.check_address.bind(this);
    }

    componentDidMount() {
        let client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'email',
            callback: (response) => this.google_login(response),
            error_callback: (response) => this.google_error(response),
        });

        this.setState({ client: client });

        let params = get_global_state()['params'];
        if (params['address']) {
            let decodedAddress = decodeURIComponent(params['address']);
            decodedAddress = decodedAddress.replace(', USA', '');

            ajax_wrapper('GET','/api/address_search/', {'address': decodedAddress}, this.check_address)
        }
        else {
            this.setState({loaded: true})
        }
    }

    check_address(data) { 
        if (data['data'] && data['data'].length == 1) {
            this.select_property(data['data'][0])
        }
        else {
            this.setState({loaded: true})
        }
    }

    google_login(state) {
        if (state && state.access_token) {
            ajax_wrapper(
                'POST',
                '/user/google_login/',
                state,
                this.sign_up_callback,
            );
        }
    }

    google_error(state) {
        console.log('Google Error', state);
        this.setState({ error: state['message'] });
    }

    search_address(name, data) {
        if (data['address'] && data['address'].length > 2) {
            ajax_wrapper('GET','/api/address_search/', data, this.load_addresses)
        }
    }

    load_addresses(data) {
        console.log("Load Addresses", data['data'])
        this.setState({potential_addresses: data['data']})
    }

    select_property(property) {
        let params = get_global_state()['params'];
        console.log("Params", params)
        if (params && params['email']) {
            this.setState({property: property})
            let data = {
                email: params['email'],
                password: 'lowermytaxes',
                property_id: property.id,
            };
            data.email = data.email.toLowerCase();
            //console.log("data", data)
    
            ajax_wrapper('POST', '/user/register/', data, this.sign_up_callback);
        }
        else if (window.secret_react_state.user) {
            window.location.href = '/property_opportunities/' + property.id
        }
        else {
            this.setState({property: property, open_modal: true})
        }
        //window.location.href = '/property_opportunities/' + property.id
    }

    focus_input() {
        this.setState({ show_potential_address: true });
    }

    blur_input() {
        //this.setState({show_potential_address: false})
    }

    submit_email(form_data) {
        let data = {
            email: form_data['email'],
            password: 'lowermytaxes',
            property_id: this.state.property.id,
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.sign_up_callback);
    }

    sign_up_callback(value) {
        if ('error' in value) {
            this.setState({ error: value.error });
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            window.location.href = '/property_opportunities/' + this.state.property.id
        }
    }

    render() {
        let addresses = [];
        if (this.state.show_potential_address) {
            //console.log("Potential Addresses", this.state.potential_addresses)
            for (let property of this.state.potential_addresses) {
                addresses.push(
                    <AddressPicker
                        property={property}
                        select_property={this.select_property}
                    />,
                );
            }
        }

        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ border: '1px solid black' }}
                >
                    <div
                        style={{
                            marginRight: '10px',
                            display: 'inline-block',
                            lineHeight: '20px',
                        }}
                    >
                        {'Login with Google'}
                    </div>
                    <img
                        style={{
                            width: '20px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt=""
                    />
                </Button>
            );
        }

        
        let header = <h1>Are you overpaying on your property taxes? Find out today!"</h1>
        let params = get_global_state()['params'];
        if (params && params['address']) {
            header = <h1>Sorry! It seems we didn't find your address, search again to find out if you are overpaying on your property taxes.</h1>
        }
        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Save Money On Your Property Taxes and Bills</title>
                    <meta name="description" content="An easy way to find where you are overpaying on property expenses." />
                </Helmet>

                <Loading loaded={this.state.loaded}>
                    <div className="simple-card">
                        
                        {header}
                        <br />
                        
                        <Form
                            auto_set_global_state={true}
                            set_global_state={this.search_address}
                            global_state_name="address_search"
                        >
                            <h5>Search Your Address To Find Out If You Are Overpaying</h5>
                            <TextInput
                                name="address"
                                autocomplete={'off'}
                            />

                            <div style={{ position: 'relative' }}>
                                <div className="google-address-result-container">
                                    {addresses}
                                </div>
                            </div>
                        </Form>

                        <Modal show={this.state.open_modal} onHide={() => this.setState({open_modal: false})}>
                            <div style={{padding:'15px'}}>
                                <p>Are you the homeowner at: {this.state.property.address}?</p>
                                <p>Enter your email below and we'll send you a link that walks you through all the ways to reduce your property tax specific to you.</p>
                                {google_button}
                                <p>OR</p>
                                <Form submit={this.submit_email} submit_text={'Check Out My Property'}>
                                    <TextInput label='Email' name='email' required={true} />
                                </Form>

                            </div>
                        </Modal>
                           
                    </div>
                </Loading>
            </Container>
        );
    }
}
