import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    AdminDashboard,
    ObjectList,
    ManageObject,
    LoadLastURL,
    CurrentNotOffered,
    PrivacyPolicy,
    SignUp,
    Home,
    PropertyComparison,
    PropertyComparisonTwo,
    GoogleLogin,
    TaxMap,
    Welcome,
    AIBlogPost,
    Retiree,
    ExemptionFunnel,
    ViewFormSubmissions,
    FunnelPerformance,
    AddressSearch,
    PropertyOpportunities,
    CreateExemption,
    ExemptionList,
    ElderlyExemption,
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },
    {
        path: 'login',
        element: <Login />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                path: 'google_login',
                element: <GoogleLogin />,
            },
            {
                path: 'property_comparison/:property_id',
                element: <PropertyComparisonTwo />,
            },
            {
                path: 'property_comparison_two/:property_id',
                element: <PropertyComparisonTwo />,
            },
            {
                path: 'tax_map',
                element: <TaxMap />,
            },
            {
                path: '',
                element: <TaxMap />,
            },
            {
                path: 'sign_up',
                element: <SignUp />,
            },
            {
                path: 'property_tax_guides/:state/:county',
                element: <AIBlogPost />,
            },
            {
                path: 'property_tax_guides/:state/:county/:city',
                element: <AIBlogPost />,
            },
            {
                path: '/retiree',
                element: <Retiree />,
            },
            {
                path: '/colorado_senior_exemption',
                element: <ExemptionFunnel />,
            },
            {
                path: '/alaska_senior_exemption',
                element: <ExemptionFunnel />,
            },
            {
                path: '/address_search',
                element: <AddressSearch />,
            },
            {
                path: '/property_opportunities/:property_id',
                element: <PropertyOpportunities />,
            },
            {
                path: '/elderly_exemption',
                element: <ElderlyExemption />,
            },
            
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                path: '/welcome',
                element:<Welcome />,
            },
            
            
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            {
                path: 'form_submissions',
                element:<ViewFormSubmissions />,
            },
            {
                path: 'funnel_performance',
                element:<FunnelPerformance />,
            },
            {
                path: 'exemption_list',
                element: <ExemptionList />
            },
            {
                path: 'create_exemption/:id',
                element: <CreateExemption />
            },
            {
                path: 'create_exemption',
                element: <CreateExemption />
            },
        ],
    },
];

export default routes;
