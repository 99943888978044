import React, { Component } from 'react';
import { Button, Modal, Form, TextInput } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class UnlockButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    submit = (value) => {
        let name = value['name'];
        let email = value['email'];
        let params = get_global_state()['params'];
        let property_id = params['property_id'];

        let data = {
            name: name,
            email: email,
            password: 'lowermytaxes',
            property_id: property_id,
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.login_callback);
    };

    login_callback = (value) => {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            let params = get_global_state()['params'];
            let property_id = params['property_id'];

            window.location.href = '/property_opportunities/' + property_id;
        }
    };

    render() {
        let style = Object.assign(
            { display: 'inline-block' },
            this.props.style,
        );

        if (window.secret_react_state['user']) {
            return null;
        } else {
            return (
                <div style={style}>
                    <Button
                        className="gradient-border"
                        {...this.props}
                        onClick={() => this.setState({ show: true })}
                    >
                        <div
                            className="gradient-border-content"
                            style={{ fontWeight: 500 }}
                        >
                            {'Unlock'}
                            <img src="/static/images/padlock.svg"></img>
                        </div>
                    </Button>
                    <Modal
                        show={this.state.show}
                        style={{ width: '600px' }}
                        onHide={() => this.setState({ show: false })}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <h1
                                style={{ fontSize: '48px', fontWeight: 'bold' }}
                            >
                                You could save{' '}
                                <span className="gradient-color">$1000s</span>{' '}
                                on your taxes!
                            </h1>
                            <h5>
                                Give us a little more of information about
                                yourself so we can send you the exact number!
                            </h5>
                        </div>
                        <br />

                        <Form
                            submit_className="gradient big-submit"
                            submit_text="Submit"
                            submit={this.submit}
                        >
                            <TextInput
                                placeholder="Type your name"
                                name="name"
                                className="fancy-input-dark"
                            />
                            <TextInput
                                placeholder="@ Type your email"
                                name="email"
                                className="fancy-input-dark"
                            />
                        </Form>
                    </Modal>
                </div>
            );
        }
    }
}
