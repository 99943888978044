import React, { Component } from 'react';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Footer extends Component {
    render() {
        //let checker = new UserValidator();
        //let is_logged_in = checker.logged_id();
        //let is_staff = checker.is_staff();
        //let no_footer = false;

        let footer = (
            <div className="fancy-footer gradient-background-dark">
                <div className="row" style={{ padding: '20px 0px' }}>
                    <div
                        className="col-6"
                        style={{ padding: '20px 0px 20px 60px' }}
                    >
                        <div
                            style={{
                                textAlign: 'left',
                                display: 'inline-block',
                            }}
                        >
                            <a href="/">
                                <img
                                    src="/static/images/logo_white.svg"
                                    style={{
                                        height: '38px',
                                        marginBottom: '20px',
                                    }}
                                ></img>
                            </a>
                            <h1>Lower your taxes,</h1>
                            <h1 className="gradient-color">
                                keep more of your money,
                            </h1>
                            <h1>Check your savings now!</h1>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row" style={{ paddingTop: '20px' }}>
                            <div className="col-4">
                                <div style={{ fontWeight: 500 }}>Company</div>
                                <a className="footer-item">Home</a>
                                <a className="footer-item">Login</a>
                                <a className="footer-item">About</a>
                                <a className="footer-item">Tax Map</a>
                            </div>
                            <div className="col-4">
                                <div style={{ fontWeight: 500 }}>Contact</div>
                                <a className="footer-item">
                                    <img
                                        src="/static/images/insta.svg"
                                        style={{
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    ></img>
                                    Instagram
                                </a>
                                <a className="footer-item">
                                    <img
                                        src="/static/images/fbook.svg"
                                        style={{
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    ></img>
                                    Facebook
                                </a>
                                <a className="footer-item">
                                    <img
                                        src="/static/images/twitter.svg"
                                        style={{
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    ></img>
                                    Twitter
                                </a>
                                <a className="footer-item">
                                    <img
                                        src="/static/images/tiktok.svg"
                                        style={{
                                            height: '16px',
                                            marginRight: '5px',
                                        }}
                                    ></img>
                                    TikTok
                                </a>
                            </div>
                            <div className="col-4">
                                <div style={{ fontWeight: 500 }}>More</div>
                                <a className="footer-item">
                                    New York, New York
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        background: '#1B1B1B',
                        borderRadius: '100px',
                        padding: '6px 12px',
                        margin: '0px',
                    }}
                    className="row"
                >
                    <div className="col-4" style={{ alignSelf: 'center' }}>
                        © 2024 HomeBase Inc. All rights reserved.
                    </div>
                    <div
                        className="col-4"
                        style={{ textAlign: 'center', alignSelf: 'center' }}
                    >
                        <img src="/static/images/bbb.svg" />
                    </div>

                    <div
                        className="col-4"
                        style={{ alignSelf: 'center', textAlign: 'right' }}
                    >
                        <a
                            style={{ marginLeft: '20px' }}
                            href="https://www.homebase.today/privacy"
                        >
                            Privacy Policy
                        </a>

                        <a
                            style={{ marginLeft: '20px' }}
                            href="https://www.homebase.today/terms-of-service"
                        >
                            Terms & Conditions
                        </a>
                    </div>
                </div>
            </div>
        );

        return footer;
    }
}

export default Footer;
